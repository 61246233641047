/* stylelint-disable */
@import "../../scss/alert";

.flash-alerts-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-flash-alerts;
}

.alert-dismissible {
  .close {
    top: 21px;
    right: map-get($spacers, 4);
    padding: 0;
    opacity: 1 !important;
    font-size: 1rem;

    span {
      color: color("gray");
    }

    &-icon {
      left: 25%;
      top: 4%;
      line-height: 1.5;
    }

    &:hover {
      opacity: 1 !important;
      color: color("gray");

      &-icon {
        color: color("gray");
      }
    }
  }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant(
      color("#{$color}-lighter"),
      color("#{$color}-light"),
      color($color)
    );
  }
}

.alert-flash {
  width: $alert-flash-width;
  max-width: $alert-flash-width;
  display: flex;
}

.alert-icon {
  margin-right: map-get($spacers, 3);
  font-size: $alert-icon-size;
  line-height: 24px;
}

.alert-close {
  $this: &;

  width: $alert-progress-bar-diameter;
  height: $alert-progress-bar-diameter;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-width: $alert-progress-bar-width;
    border-style: solid;
    position: absolute;
    top: 0;
    left: 0;
  }

  & > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &-progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $alert-progress-bar-width;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  &-progress-left {
    left: 0;

    #{$this}-progress-bar {
      left: 100%;
      border-top-right-radius: $alert-progress-bar-diameter / 2;
      border-bottom-right-radius: $alert-progress-bar-diameter / 2;
      border-left: 0;
      -webkit-transform-origin: center left;
      transform-origin: center left;
    }
  }

  &-progress-right {
    right: 0;

    #{$this}-progress-bar {
      left: -100%;
      border-top-left-radius: $alert-progress-bar-diameter / 2;
      border-bottom-left-radius: $alert-progress-bar-diameter / 2;
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
    }
  }
}

.alert-close-progress-left {
  .alert-close-progress-bar {
    animation: progress-bar calc(var(--dismiss-delay) / 2) linear forwards
      calc(var(--dismiss-delay) / 2);
  }
}

.alert-close-progress-right {
  .alert-close-progress-bar {
    animation: progress-bar calc(var(--dismiss-delay) / 2) linear forwards;
  }
}

@keyframes progress-bar {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

/* stylelint-disable */

.badge {
  display: inline-block;
  padding: $badge-padding-y $badge-padding-x;
  font-size: $badge-font-size;
  font-weight: $badge-font-weight;
  line-height: $badge-line-height;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border: $badge-border;
  @include border-radius($badge-border-radius);

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
  .badge-#{$color} {
    @include badge-variant(color("#{$color}-lighter"), $value, $value, color("#{$color}-light"));
  }
}

//
// Filled Badges
//
@each $color, $value in $theme-colors {
  .badge-#{$color}.badge-filled {
    $light-badges: "success", "danger", "warning";
    $color-default: $value;
    $color-light: color("#{$color}-light");
    $text-color: color("white");

    @if contains($light-badges, $color) {
      @include badge-variant($color-light, $color-light, $text-color, $text-color);
    } @else {
      @include badge-variant($color-default, $color-default, $text-color, $text-color);
    }
  }
}

//
// Default badge color
//
.badge-default {
  color: color("gray");
  background-color: color("gray-lightest");
  border-color: color("gray-light");

  .badge-icon {
    color: color("gray-light");
  }

  &.badge-filled {
    color: color("white");
    background-color: color("gray");
    border-color: color("gray");

    .badge-icon {
      color: color("white");
    }
  }
}

//
// Button Sizes
//

.badge-lg {
  @include badge-size($badge-padding-y-lg, $badge-padding-x-lg, $badge-font-size-lg, $badge-line-height-lg, $badge-border-radius-lg);
}

.badge-sm {
  @include badge-size($badge-padding-y-sm, $badge-padding-x-sm, $badge-font-size-sm, $badge-line-height-sm, $badge-border-radius-sm);
}



@import "../../scss/pagination";

.pagination {
  $this: &;

  .page-link {
    text-align: center;
  
    &:hover {
      z-index: 2;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border-color;
    }
  
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  
  .page-item {
    &:first-child {
      .page-link {
        border-left: $pagination-border-width solid $pagination-border-color;
      }
    }
    &:last-child {
      .page-link {
        border-right: $pagination-border-width solid $pagination-border-color;
      }
    }
  
    &.active .page-link {
      z-index: 1;
      color: $pagination-active-color;
      background-color: $pagination-active-bg;
      border-color: $pagination-border-color;
    }
  }

  &:not(#{$this}-sm) {
    .page-link {
      min-width: $pagination-link-min-width;
      min-height: $pagination-link-min-height;
    }
  }
}

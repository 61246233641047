// Main Navigation
// =========================================================

%link {
  color: rgba(map-get($colors, "white"), 0.55);
  font-size: 12px;
  text-decoration-line: none;
  transition: all 0.15s ease-in;

  &:hover,
  &.active {
    color: rgba(map-get($colors, "white"), 1);
  }
}

.nav {
  $this: &;

  &.nav-header {
    z-index: 5;
    background-color: map-get($colors, "primary-dark");
    min-height: 66px;
    overflow: visible;
    padding: 0 map-get($spacers, 4);

    #{$this}-section:first-child {
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    #{$this}-link:not(.brand-link) {
      @extend %link;
    }

    .brand {
      padding-right: map-get($spacers, 4);

      &-link {
        display: inherit;
        height: 40px;
      }
    }
  }
}

@media (min-width: map-get($container-max-widths, "xl")) {
  .nav.nav-header.max-width {
    padding-left: calc(100% / 2 - #{px-to-rem(map-get($container-max-widths, "xl")) / 2 - map-get($spacers, 4)});
    padding-right: calc(100% / 2 - #{px-to-rem(map-get($container-max-widths, "xl")) / 2 - map-get($spacers, 4)});
  }
}

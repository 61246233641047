/* stylelint-disable */
@import "../../scss/input-group";

.input-group-cta {
  padding: map-get($spacers, 3);
  position: relative;
  display: flex;
  align-items: center;
  border-color: $input-border-color;
  border-width: $input-border-width;
  border-radius: $border-radius;
  border-style: solid;
  background-color: color("white");

  > .input-group-icon {
    color: color("gray-light");
    display: flex;
    margin-left: map-get($spacers, 1);
  }

  > .form-control {
    flex-grow: 1;
    width: auto;
    margin-right: map-get($spacers, 1);
    border: none;
    box-shadow: none !important;
  }

  > .btn {
    flex-shrink: 0;
  }
}

.max-width-container {
  margin-left: map-get($spacers, 4);
  margin-right: map-get($spacers, 4);

  > .table {
    margin-left: - map-get($spacers, 4);
    margin-right: - map-get($spacers, 4);
    width: calc(100% + 2 * #{map-get($spacers, 4)});
  }
}

@media (min-width: map-get($container-max-widths, "xl")) {
  .max-width-container {
      max-width: px-to-rem(map-get($container-max-widths, "xl")) - 2 * map-get($spacers, 4);
      margin-left: auto;
      margin-right: auto;
  }
}

.max-width-container {
  margin-bottom: map-get($spacers, 6);

  > h2 {
    margin-top: map-get($spacers, 5);
    margin-bottom: map-get($spacers, 4);
  }

  > .pagination {
    margin-top: map-get($spacers, 6);
    margin-bottom: map-get($spacers, 7);
  }
}

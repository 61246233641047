/* stylelint-disable */
@import "../../scss/tables";

.table {
  margin-bottom: 0;

  thead th {
    border-top: 0;
    border-bottom: (1 * $table-border-width) solid $table-border-color;
    font-size: .75rem;
    letter-spacing: 1px;
    line-height: 1.5rem;
    padding: $table-cell-padding-sm $table-cell-padding;
    text-transform: uppercase;
    text-align: left;
  }

  &:not(.table-dark) thead th {
    color: $black;
  }

  th,
  td {
    vertical-align: middle;
  }

  &:not(.table-bordered) th:not(:last-child),
  &:not(.table-bordered) td:not(:last-child) {
    padding-right: 0;
  }

  tr:last-child > th,
  tr:last-child > td {
    border-bottom: (1 * $table-border-width) solid $table-border-color;
  }

  &-sm {
    td {
      padding: $table-cell-padding-sm $table-cell-padding;
    }
  }

  &-borderd {
    thead {
      th,
      td {
        border-bottom-width: 1 * $table-border-width;
      }
    }
  }

  //Fixed table columns grid
  &-fixed {
    table-layout: fixed;
  }

  @for $value from 1 through 12 {

    $width: percentage($value/12);
    $half: percentage(0.5/12);

    &-col-#{$value} {
      width: $width;

      &-half {
        width: $width + $half;
      }
    }
  }
}

/* stylelint-disable */
@import "../../scss/forms";

.form-text {
  color: $gray;
}

label {
  color: map-get($colors, 'black');
  font-size: .875rem;
  line-height: 1.5rem;
}

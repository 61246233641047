// General Navigation
// =========================================================

.nav {
  $this: &;

  align-items: stretch;
  display: flex;
  justify-content: space-between;

  &-section {
    align-items: center;
    display: inherit;
    list-style-type: none;
    margin: 0;
    padding: 0;

    #{$this}-item {
      position: relative;
      padding-top: 1rem;
      padding-bottom: 1rem;

      &:not(:last-child) {
        margin-right: 40px;
      }
    }

    &.small-space {
      #{$this}-item:not(:last-child) {
        margin-right: map-get($spacers, 3);
      }
    }

    #{$this}-item,
    #{$this}-link {
      align-items: inherit;
      display: inherit;
      height: 100%;
    }

    #{$this}-link {
      cursor: pointer;
      font-family: 'LL Circular Pro';
      font-weight: 500;
    }
  }
}

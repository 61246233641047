@mixin badge-variant($bg, $border, $color, $icon-color) {
  color: $color;
  background-color: $bg;
  border-color: $border;

  .badge-icon {
    color: $icon-color;
  }
}

// Badge sizes
@mixin badge-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}
